// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualites-js": () => import("./../src/pages/actualites.js" /* webpackChunkName: "component---src-pages-actualites-js" */),
  "component---src-pages-cgu-jsx": () => import("./../src/pages/cgu.jsx" /* webpackChunkName: "component---src-pages-cgu-jsx" */),
  "component---src-pages-donnees-personnelles-jsx": () => import("./../src/pages/donnees-personnelles.jsx" /* webpackChunkName: "component---src-pages-donnees-personnelles-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-mentions-legales-jsx": () => import("./../src/pages/mentions-legales.jsx" /* webpackChunkName: "component---src-pages-mentions-legales-jsx" */),
  "component---src-pages-presse-js": () => import("./../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-info-post-js": () => import("./../src/templates/info-post.js" /* webpackChunkName: "component---src-templates-info-post-js" */)
}

